<template>
	<v-container fluid></v-container>
</template>
<script>
import { GetLongUrl } from "@/core/lib/setting.lib";
export default {
	data() {
		return {
			short_url: null,
		};
	},
	methods: {
		getLongURL() {
			GetLongUrl(this.short_url)
				.then((data) => {
					window.location.replace(data.long_url);
				})
				.catch(() => {
					this.$router.push({ name: "404" });
				});
		},
	},
	mounted() {
		if (this.short_url) {
			this.getLongURL();
		} else {
			this.$router.push({ name: "404" });
		}
	},
	beforeMount() {
		this.short_url = this.$route.params.url || null;
	},
};
</script>
